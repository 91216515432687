@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Coming+Soon&family=Open+Sans+Condensed:wght@300&family=Open+Sans:wght@300&family=Yanone+Kaffeesatz:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300&display=swap");

body {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
}
.root {
  background-color: #f6f6f6;
  color: #000;
  display: flex;
  flex-grow: 1;
}

.DraftEditor-root {
  font-size: 14px;
  font-weight: 100;
}
.public-DraftStyleDefault-ol {
  margin: 5px 0;
  padding: 0;
}

.public-DraftStyleDefault-ul {
  margin: 5px 0;
  padding: 0;
}

.css-19kzrtu {
  padding: 0px !important;
}
/* ---------Sidebar---------*/
.sidebarIcon {
  padding-right: 10px;
  position: relative;
  top: -2px;
}

@media only screen and (max-width: 1500px) {
  .summaryImage {
    width: 100%;
  }
}

/* @import "react-multi-carousel/lib/styles.css"; */
@import "../node_modules/react-multi-carousel/lib/styles.css";

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.react-multi-carousel-dot--active button {
  background: #c3e8d0 !important;
}
.react-multi-carousel-dot button {
  border-color: #c3e8d0 !important;
  background: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
