.mention {
  color: #000000;
  text-decoration: underline;
}

.mentionSuggestions {
  border-top: 1px solid #eee;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  margin: 0px;
  z-index: 9999;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 300px;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
  display: table-cell;
  vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px 7px 10px;
  width: 300px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.mentionSuggestionsEntry:active {
  background-color: #fef0d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px 7px 10px;
}

.mentionSuggestionsEntryFocused {
  background-color: #fef0d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px 7px 10px;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.mentionSuggestionsEntryTitle {
  font-size: 80%;
  color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fddfb0;
}
