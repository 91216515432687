@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&family=Coming+Soon&family=Open+Sans+Condensed:wght@300&family=Open+Sans:wght@300&family=Yanone+Kaffeesatz:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300&display=swap);
body {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
}
.root {
  background-color: #f6f6f6;
  color: #000;
  display: flex;
  flex-grow: 1;
}

.DraftEditor-root {
  font-size: 14px;
  font-weight: 100;
}
.public-DraftStyleDefault-ol {
  margin: 5px 0;
  padding: 0;
}

.public-DraftStyleDefault-ul {
  margin: 5px 0;
  padding: 0;
}

.css-19kzrtu {
  padding: 0px !important;
}
/* ---------Sidebar---------*/
.sidebarIcon {
  padding-right: 10px;
  position: relative;
  top: -2px;
}

@media only screen and (max-width: 1500px) {
  .summaryImage {
    width: 100%;
  }
}

/* @import "react-multi-carousel/lib/styles.css"; */

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.react-multi-carousel-dot--active button {
  background: #c3e8d0 !important;
}
.react-multi-carousel-dot button {
  border-color: #c3e8d0 !important;
  background: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MentionsStyles_mention__1usGO {
  color: #000000;
  text-decoration: underline;
}

.MentionsStyles_mentionSuggestions__2gTAt {
  border-top: 1px solid #eee;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  margin: 0px;
  z-index: 9999;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.MentionsStyles_mentionSuggestionsEntryContainer__ORnjO {
  display: table;
  width: 300px;
}

.MentionsStyles_mentionSuggestionsEntryContainerLeft__2wRqJ,
.MentionsStyles_mentionSuggestionsEntryContainerRight__zYu4x {
  display: table-cell;
  vertical-align: middle;
}

.MentionsStyles_mentionSuggestionsEntryContainerRight__zYu4x {
  width: 100%;
  padding-left: 8px;
}

.MentionsStyles_mentionSuggestionsEntry__31j16 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px 7px 10px;
  width: 300px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.MentionsStyles_mentionSuggestionsEntry__31j16:active {
  background-color: #fef0d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px 7px 10px;
}

.MentionsStyles_mentionSuggestionsEntryFocused__3EF2l {
  background-color: #fef0d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px 7px 10px;
}

.MentionsStyles_mentionSuggestionsEntryText__2kkt9,
.MentionsStyles_mentionSuggestionsEntryTitle__GgIQN {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.MentionsStyles_mentionSuggestionsEntryTitle__GgIQN {
  font-size: 80%;
  color: #a7a7a7;
}

.MentionsStyles_mentionSuggestionsEntryAvatar__3aXyA {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fddfb0;
}

